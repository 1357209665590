import { ready } from 'jquery';
import lottieWeb from 'lottie-web';
import UIkit from 'uikit';
import Lenis from 'lenis';

// SCROLL
const lenis = new Lenis({
  lerp: 0.07,
  // smoothWheel: true,
  // smoothTouch: false,
})

// function updateOpacity() {
//   // Get the position of the important text section
//   const elements = document.querySelectorAll('.vy_important_letter');

//   elements.forEach((element, index) => {
//     const rect = element.getBoundingClientRect();
//     const windowHeight = window.innerHeight;

//     // Calculate opacity based on element position relative to the viewport
//     let opacity = 1 - (rect.top / windowHeight);

//     // Keep opacity between 0.6 and 1
//     opacity = Math.min(1, Math.max(0.6, opacity));

//     // Apply opacity to the element
//     element.style.opacity = opacity;
//   });
// }
// lenis.on('scroll', updateOpacity);

// lenis.on('scroll', (e) => {
//   updateTransform();
// })

function raf(time) {
  lenis.raf(time)
  requestAnimationFrame(raf)
}

requestAnimationFrame(raf)

// window.addEventListener('load', updateOpacity);

UIkit.util.on('[data-uk-modal]', 'show', function (e) {
  lenis.stop();
});
UIkit.util.on('[data-uk-modal]', 'hide', function (e) {
  lenis.start();
});
$(document).on('beforescroll', "body", function() {
  lenis.stop();
  lenis.start();
});

// SCROLL TO HASH
function scrollToHash() {
  var hash = window.location.hash;
  if (hash) {
      var target = jQuery(hash);
      if (target.length) {
          UIkit.scroll(target).scrollTo(target);
      }
  }
}

// OPEN PODUCTS FEATURED
if ($('.vy_portfolios_featured').length) {
  UIkit.modal('.vy_portfolios_featured').show();

  function updatePortfolioModal() {
    var $container = $('.vy_portfolios_featured');
    var containerScrollTop = $container.scrollTop();
    var containerHeight = $container.height();
    var containerBottom = containerScrollTop + containerHeight;
    var viewportMiddle = containerScrollTop + containerHeight / 2;

    // Iterate over each .vy_portfolios_featured_item_wrapper within the container
    $container.find('.vy_portfolios_featured_item_wrapper').each(function() {
      var $item = $(this);
      var $content = $item.find('.vy_portfolios_featured_item_content');
      var itemTop = $item.offset().top - $container.offset().top + containerScrollTop;
      var itemHeight = $item.outerHeight();
      var itemBottom = itemTop + itemHeight;
      var itemMiddle = itemTop + itemHeight / 2;

      // Existing transformation on the wrapper itself
      var distanceToBottom = containerBottom - itemBottom;
      var maxTranslateY = 100; // Maximum amount to translate in pixels
      var translateY = (distanceToBottom / containerHeight) * maxTranslateY;

      // Cap translateY to avoid extreme values
      translateY = Math.max(-maxTranslateY, Math.min(translateY, maxTranslateY));

      // Apply the transformation to each individual wrapper item
      $item.css({
        transform: `translate3d(0px, ${translateY}px, 0px)`
      });

      // New functionality: Keep content centered within viewport
      var offset = viewportMiddle - itemMiddle;
      var maxOffset = (itemHeight / 2) - ($content.outerHeight() / 2);

      // // Ensure offset is within bounds of the parent item
      // offset = Math.max(-maxOffset, Math.min(offset, maxOffset));

      // Apply the transformation to keep the content centered in the viewport
      $content.css({
        transform: `translateY(${offset}px)`
      });
    });
  }



  const modalLenis = new Lenis({
    lerp: 0.07,
    wrapper: document.querySelector('.vy_portfolios_featured')
  });

  modalLenis.on('scroll', (e) => {
    updatePortfolioModal();
  });

  function modalRaf(time) {
    modalLenis.raf(time);
    requestAnimationFrame(modalRaf);
  }
  requestAnimationFrame(modalRaf);
}



// ANIM SVG
var animation = new Array();

function loadBMAnimation(element,i) {
  var $animation_params = {
    name: 'animation '+i,
    container: element,
    renderer: "svg",
    loop: Boolean($(element).data('loop')),
    autoplay: Boolean($(element).data('autoplay')),
    path: $(element).data('path')
  }
  if ($(element).attr('data-preserveaspectratio')) { $animation_params['rendererSettings'] = { preserveAspectRatio:$(element).data('preserveaspectratio') } }
  animation[i] = lottieWeb.loadAnimation($animation_params);

  var util = UIkit.util;
  var el = util.$(element);
  UIkit.scrollspy(el, { repeat: true });
  util.on(el,'inview', function(){
    animation[i].play();
  });

  if(Boolean($(element).data('repeat'))){
    util.on(el,'outview', function(){
      animation[i].stop();
    });
  }

  animation[i].addEventListener('DOMLoaded', function () {
    console.log('DOMLoaded');
    $(element).addClass('loaded');
    // magnetJson();
  });
  animation[i].addEventListener('enterFrame', function (event) {
    // console.log(event.currentTime);
    if (event.currentTime < 1 && !$(element).hasClass('playing')) {
      console.log('enterFrame');
      $(element).addClass('playing');
    }
  });

  animation[i].addEventListener('complete', function () {
    console.log('complete');
    $(element).removeClass('playing');
  });
  animation[i].addEventListener('loopComplete', function () {
    console.log('loopComplete test');
    $(element).removeClass('playing');
    setTimeout(function() {
      $(element).addClass('playing');
    }, 50);
});
}

// MAGNET EFFECT
function addMagnet(item) {
  const sensitivity = 150;
  console.log(item);

  $(document).on('mousemove', function(e) {
    const mouseX = e.pageX;
    const mouseY = e.pageY;

    const itemX = item.offset().left + item.width() / 2;
    const itemY = item.offset().top + item.height() / 2;

    const distanceX = mouseX - itemX;
    const distanceY = mouseY - itemY;

    const distance = Math.sqrt(distanceX * distanceX + distanceY * distanceY);

    if (distance < sensitivity) {
      const offsetX = - (distanceX / sensitivity) * 25;
      const offsetY = - (distanceY / sensitivity) * 25;
      item.css('transform', `translate(${offsetX}px, ${offsetY}px)`);
      console.log(offsetX, offsetY);

    } else {
      item.css('transform', 'translate(0, 0)');
    }
  });
}
function magnetJson() {
  console.log('magnet effect 2');

  $('.vy_singleteam_header_anim svg>g>g>g>g>path').each(function() {
    addMagnet($(this));
  });
  $('.vy_singleteam_header_anim svg>g>g>g[mask]').each(function() {
    addMagnet($(this));
  });
}

$(function() {
  var element = document.getElementsByClassName("js_animation");

  for (var i = 0; i < element.length; i++) {
    loadBMAnimation(element[i],i);
  }

  //fix scroll to hash Lenis using Uikit
  scrollToHash();

  console.log('test member photo');

  //hover team listing
  $(".vy_team_member_wrapper").hover(
    function(e) {
      var element = $(this).find(".vy_team_member_photo_hover");

      // Get the element's offset and dimensions
      var offset = element.offset();
      var width = element.width();
      var height = element.height();

      // Calculate the percentage position of the mouse relative to the element
      var xPercent = ((e.pageX - offset.left) / width) * 100;
      var yPercent = ((e.pageY - offset.top) / height) * 100;

      console.log(xPercent, yPercent);

      // Use requestAnimationFrame to apply the clip-path after resetting it
      requestAnimationFrame(function() {
        // Set the clip-path with percentages for hover
        element.css({
          "mask-position": `${xPercent}% ${yPercent}%`
        });
      });
    },
    function(e) {
      var element = $(this).find(".vy_team_member_photo_hover");

      // Get the element's offset and dimensions
      var offset = element.offset();
      var width = element.width();
      var height = element.height();

      // Calculate the percentage position of the mouse relative to the element
      var xPercent = ((e.pageX - offset.left) / width) * 100;
      var yPercent = ((e.pageY - offset.top) / height) * 100;

      console.log(xPercent, yPercent);

      // Set the clip-path with percentages for mouse leave
      element.css({
        "mask-position": `${xPercent}% ${yPercent}%`
      });
    }
  );

  // HOVER LISTING SERVICES
  var hoverElement = $('.vy_services_hover');
    var isUpdating = false;  // To prevent continuous updates

    // Function to update position
    function updatePosition(event) {
        var container = $('.vy_services_items');
        var containerOffset = container.offset();
        var containerWidth = container.outerWidth();
        var containerHeight = container.outerHeight();

        var mouseX = event.pageX - containerOffset.left;
        var mouseY = event.pageY - containerOffset.top;

        var right = containerWidth - mouseX;
        var bottom = containerHeight - mouseY;

        var hoverWidth = hoverElement.outerWidth();
        var hoverHeight = hoverElement.outerHeight();

        right -= hoverWidth / 2;
        bottom -= hoverHeight / 2;

        if (right < 0) {
          right = 0;
        }
        // if (bottom < 0) {
        //   bottom = 0;
        // }

        hoverElement.css({
            'right': right + 'px',
            'bottom': bottom + 'px'
        });

        isUpdating = false;  // Reset the update flag
    }

    $('.vy_services_item').on('mousemove', function(event) {
        if (!isUpdating) {
            isUpdating = true;
            window.requestAnimationFrame(function() {
                updatePosition(event);
            });
        }
    });







});
